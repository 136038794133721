import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { CircularProgress, withStyles } from "@material-ui/core";
import { columnsInternacaoList } from "../constants";
import Table from "../../../../components/Table/Table";
import CloseIcon from "../../../../components/Icon/Close";
import Dialog from "../../../../components/Dialog/Dialog";
import { Button } from "../../../../components/ui/Buttons";
import {
  findBySujeitoAtencaoInternacaoId,
  findSujeitoAtencaoInternacaoByAgendamentoId,
} from "../../../../services/InternacaoService";
import MensagemListaVazia from "../../../../components/Mensagem/MensagemListaVazia";

const InternacaoListModal = observer(
  ({
    openModal,
    handleClose,
    atendimentoStore,
    classes,
    handleSelectInternacao,
  }) => {
    const [internacoes, setInternacoes] = useState([]);
    const [loading, setLoading] = useState(false);
    const { objView } = atendimentoStore;

    useEffect(() => {
      getInternacoes();
    }, []);

    const getInternacoes = async () => {
      try {
        setLoading(true);
        const internacoes = await findSujeitoAtencaoInternacaoByAgendamentoId(
          objView.id
        );

        setInternacoes(internacoes);
      } catch (e) {
        const notification = {
          isOpen: true,
          message: "Erro ao carregar as internações",
          variant: "error",
        };
        atendimentoStore.showAlertMessage(notification);
      } finally {
        setLoading(false);
      }
    };

    const handleSelect = async (internacaoId) => {
      try {
        const response = await findBySujeitoAtencaoInternacaoId(internacaoId);
        handleSelectInternacao(response);
        handleClose();
      } catch (e) {
        const notification = {
          isOpen: true,
          message: "Erro ao carregar a internação",
          variant: "error",
        };
        atendimentoStore.showAlertMessage(notification);
      }
    };

    const handleAdicionarInternacao = () => {
      handleSelectInternacao(null);
      handleClose();
    };

    return (
      <Dialog
        open={openModal}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
        }}
      >
        <div className={classes.container}>
          <div className={classes.header}>
            <h1>Internações</h1>
            <Button shape="circle" onClick={handleClose} bgColor="#FB7676">
              <CloseIcon />
            </Button>
          </div>
          <Table
            dados={internacoes}
            columns={columnsInternacaoList}
            clickable={true}
            handleClick={handleSelect}
          />

          {loading && (
            <div className={classes.loading}>
              <CircularProgress color="primary" size={24} />
            </div>
          )}
          {!loading && internacoes.length === 0 && (
            <div>
              <MensagemListaVazia />
            </div>
          )}
        </div>
        <div className={classes.footer}>
          <Button onClick={handleAdicionarInternacao} kind="primary">
            Adicionar
          </Button>
        </div>
      </Dialog>
    );
  }
);

const styles = {
  paper: {
    width: "400px",
    height: "400px",
  },
  container: {
    height: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px 32px 20px",

    "& h1": {
      fontSize: "20px",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "20px 32px 24px",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "8px",
  },
};

const InternacaoListModalWithStyles = withStyles(styles)(InternacaoListModal);
export default inject("atendimentoStore")(InternacaoListModalWithStyles);
