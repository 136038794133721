import React, { useEffect, useState } from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import PageTitle from "../../../components/PageTitle/PageTitle";
import {
  Container,
  ContentButtons,
  DialogStyled,
  Header,
  Title,
} from "./styles";
import {
  componentTab,
  dadosComplementaresDefault,
  internacaoDefault,
  optionsTab,
  procedimentoDefault,
  responsavelDefault,
  tabs,
  tecnicasUtilizadas,
  tiposEncaminhamentos,
  viaAcessos,
} from "./constants";
import { Button } from "../../../components/ui/Buttons";
import CloseIcon from "../../../components/Icon/Close";
import SaveIcon from "../../../components/Icon/Save";
import {
  darAltaPaciente,
  findByIdSujeitoAtencao,
  saveInternacao,
} from "../../../services/InternacaoService";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import { getProfissionalSaudeLogado } from "../../../services/UsuarioService";
import FinanceiroMenuIcon from "../../../components/Icon/FinanceiroMenu";
import moment from "moment";
import { linkGuiaFaturamento } from "../../../services/FinanceiroService";
import string from "../../../utils/string";
import AltaIcon from "../../../components/Icon/AltaIcon";
import ModalAltaPaciente from "./components/ModalAltaPaciente";
import AtencaoIcon from "../../../components/Icon/AtencaoIcon";
import ModalAlerta from "./components/ModalAlerta";

const InternacaoModal = observer(
  ({
    openModal,
    handleClose,
    atendimentoStore,
    agendamentoId,
    dadosInternacao,
    prontuarioStore,
    unidadeStore,
  }) => {
    const { objView } = atendimentoStore;
    const { sujeitoAtencao } = objView;
    const { convenio, profissionalSaude, solicitante, procedimentos, data } =
      objView || {};

    const [tabSelected, setTabSelected] = useState(tabs.DADOS_CADASTRAIS);
    const [internacao, setInternacao] = useState(
      dadosInternacao || internacaoDefault
    );
    const [loadingSave, setLoadingSave] = useState(false);
    const [hasAcessoCids,setHasAcessoCIDs]=useState(false)
    const [openModalDarAlta, setOpenModalDarAlta] = useState(false)
    const [dadosAlta, setDadosAlta] = useState(dadosInternacao?.alta || {})
    const [utilizaPlugzapi,setUtilizaPlugzapi]=useState(null)
    const { dadosComplementares } = internacao || {};
    const [openAvisoSalvar,setOpenAvisoSalvar]=useState(false)
    const [internacaoComparacao,setInternacaoComparacao]=useState({...internacaoDefault})

    const buttonsContainerStyle = {
      display: "grid",
      gridTemplateColumns: "auto auto auto auto auto auto auto",
      borderRadius: "16px",
    };

    useEffect(() => {
      internacao?.id ? adaptaDadosInternacao() : getDadosInternacao();
      getPermissao()
    }, []);

    useEffect(() => {
      selectAgendamento()
      getPermissao()
      getConfiguracaoUnidade()
      atendimentoStore.isModalInternacao = true
    }, []);
    const getConfiguracaoUnidade = ()=>{
      const { configuracaoUnidade } = unidadeStore;
      setUtilizaPlugzapi(configuracaoUnidade.utilizaPlugzapi)
      
    }
    
    const selectAgendamento = () =>{
      const {objView}=atendimentoStore
      prontuarioStore.selectAgendamento(objView?.id)
    }
    
    const getPermissao = async () => {
      const profissional = await getProfissionalSaudeLogado();
      const isMedico = profissional?.tipo === 1
      setHasAcessoCIDs(isMedico)
    }
    
    const adaptaDadosInternacao = async() => {
      const { internacaoPrescricaoItens } = internacao
      
      const dadosEncaminhamento =
      tiposEncaminhamentos.length > 0 &&
      tiposEncaminhamentos.find(
        (item) => item.value === dadosComplementares.encaminhamento
      );
      
      const cids = internacao.cids.length > 0 ? internacao.cids.map((cid) => {
        const { id, sequencia, cid10Subcategoria } = cid;
        return {
          id,
          sequencia,
          cid: cid10Subcategoria,
          descricao: cid10Subcategoria?.descricao,
          diagnostico: cid10Subcategoria?.descricaoAbreviada,
        };
      }):[]

      const internacaoPrescricaoAdptada = internacaoPrescricaoItens?.map((item) => {
        return {
          drug: {
            id: item?.produto?.id,
            nome: item?.produto?.nome,
          },
          amount: parseFloat(item?.quantidade),
          routeAdm: item?.viaAdministracao,
          useType: item?.tipoUso,
          adscription: item?.adscricao,
          condutaEnfermagem: item.condutaEnfermagem,
          unidadeMedida: item?.unidadeMedida,
        }
      })

      const procedimentos = internacao?.procedimentos?.map((item) => {
        return {
          ...item,
          viaAcesso: item.viaAcesso ? viaAcessos.find(via => via.value === item.viaAcesso) : null,
          tecnicaUtilizada: item.tecnicaUtilizada ? tecnicasUtilizadas.find(tecnica => tecnica.value === item.tecnicaUtilizada) : null,
        };
      });

      const dadosInternacao = {
        ...internacao,
        dadosComplementares: {
          ...dadosComplementares,
          ...(dadosEncaminhamento && { encaminhamento: dadosEncaminhamento }),
        },
        internacaoPrescricaoItens:[...internacaoPrescricaoAdptada],
        cids,
        procedimentos,
      };

      setInternacao((prevState) => ({
        ...prevState,
        ...dadosInternacao,
      }));
    };

    const getDadosInternacao = async () => {
      const { objView } = atendimentoStore
      const { cid10Subcategorias } = objView
      const response = await findByIdSujeitoAtencao(sujeitoAtencao?.id);
      const profissionalAtendente = await getProfissionalSaudeLogado();
      const cids = cid10Subcategorias?.map((cid, index) => {
        return ({
          id: cid?.id,
          sequencia: index + 1,
          cid: cid?.codigo,
          descricao: cid?.descricao,
          diagnostico: cid?.descricaoAbreviada,
        })
      })

      const {
        endereco,
        contato,
        nomeResponsavel,
        documentoResponsavel,
      } = response;

      const procedimentosAgendamento = procedimentos.length > 0 ? procedimentos.map((procedimento, index) => ({
        ...procedimentoDefault,
        sequencia: index + 1,
        procedimento: procedimento.procedimento,
        quantidade: 1,
        dataRealizacao: data,
        profissionalSaude,
      })) : [];

      setInternacao((prevState) => ({
        sujeitoAtencao: response,
        acompanhante: {
          nome: nomeResponsavel,
          endereco,
          contato,
          documento: documentoResponsavel,
          rg: "",
          vinculo: "",
        },
        responsavel: responsavelDefault,
        dadosComplementares: {
          ...prevState.dadosComplementares,
          convenio: convenio,
          profissionalResponsavel: profissionalSaude,
          profissionalSolicitante: solicitante,
          profissionalAtendente,
        },
        leitosOcupados: [],
        procedimentos: procedimentosAgendamento,
        cids,
      }));

      setInternacaoComparacao((prevState) => ({
        sujeitoAtencao: response,
        acompanhante: {
          nome: nomeResponsavel,
          endereco,
          contato,
          documento: documentoResponsavel,
          rg: "",
          vinculo: "",
        },
        responsavel: responsavelDefault,
        dadosComplementares: {
          ...dadosComplementaresDefault,
          convenio: convenio,
          profissionalResponsavel: profissionalSaude,
          profissionalSolicitante: solicitante,
          profissionalAtendente,
        },
        leitosOcupados: [],
        procedimentos: procedimentosAgendamento,
        cids,
      }));

    };

    const changeTabSelected = (tabSelected) => {
      setTabSelected(tabSelected);
    };

    const getVariables = () => {
      const { dadosComplementares, leitosOcupados, cids, procedimentos, internacaoProdutos ,responsavel,sujeitoAtencao,acompanhante,internacaoPrescricaoItens} = internacao || {};
      const dadosComplementaresAdapted = {
        ...internacao.dadosComplementares,
        encaminhamento: dadosComplementares?.encaminhamento?.value || null,
        convenio: dadosComplementares?.convenio?.id
          ? { id: dadosComplementares.convenio.id }
          : null,
        profissionalAtendente: dadosComplementares?.profissionalAtendente?.id
          ? { id: dadosComplementares?.profissionalAtendente?.id }
          : null,
        dataGuia: dadosComplementares?.dataGuia
          ? moment(dadosComplementares.dataGuia).format("YYYY-MM-DD")
          : null,
        numeroGuia: dadosComplementares?.numeroGuia || null,
        dataValidadeSenha: dadosComplementares?.dataValidadeSenha
          ? moment(dadosComplementares.dataValidadeSenha).format("YYYY-MM-DD")
          : null,
      };

      const {endereco,cep,documento,contato,...rest}=responsavel
      const responsavelAdaptado= {
        ...rest,
        ...(documento && {documento: string.removeCpfMask(documento)}),
        ...(responsavel?.contato?.telefonePrincipal && {
          contato: {
            telefonePrincipal:
              string.removeSpecialChars(
                responsavel?.contato?.telefonePrincipal
              ) || null,
            ...(responsavel?.contato?.telefoneSecundario && {
              telefoneSecundario:
                string.removeSpecialChars(
                  responsavel?.contato?.telefoneSecundario
                ) || null,
            }),
          },
        }),
        endereco: {
          ...endereco,
          municipio: responsavel?.municipio ? { ...responsavel?.municipio } : null,
          cep: string.removeSpecialChars(cep) || null,
        }
      }

      const leitosOcupadosAdapted =
        leitosOcupados?.length > 0
          ? leitosOcupados.map((item) => ({
            ...item,
          ...(item.id && { id: item?.id}),
            tipoLeito: item?.tipoLeito || null,
            dataInicioOcupacao: item?.dataInicioOcupacao
              ? moment(item.dataInicioOcupacao).format("YYYY-MM-DDTHH:mm:ss")
              : null,
            dataFimOcupacao: item?.dataFimOcupacao
              ? moment(item.dataFimOcupacao).format("YYYY-MM-DDTHH:mm:ss")
              : null,
          }))
          : [];

      const cidsAdapted =
        cids?.length > 0
          ? cids.map((item) => ({
              cid10Subcategoria: {id: item?.cid?.id},
              sequencia: item.sequencia,
            }))
          : [];

      const procedimentosAdapted = procedimentos?.length > 0 ?
        procedimentos.map((item) => {
          delete item.nomeProcedimento;

          return {
            ...item,
            ...(item.quantidade && {quantidade: parseInt(item.quantidade)}),
            dataRealizacao: item?.dataRealizacao ? moment(item.dataRealizacao).format("YYYY-MM-DD") : null,
            viaAcesso: item.viaAcesso?.value,
            tecnicaUtilizada: item.tecnicaUtilizada?.value,
          }
        }
      ) : [];

      const acompanhanteAdaptado = {
        ...acompanhante,
        ...(acompanhante?.documento && {documento: string.removeCpfMask(acompanhante?.documento)}),
        ...(acompanhante?.contato?.telefonePrincipal && {
          contato: {
            telefonePrincipal:
              string.removeSpecialChars(
                acompanhante?.contato?.telefonePrincipal
              ) || null,
            ...(acompanhante?.contato?.telefoneSecundario && {
              telefoneSecundario:
                string.removeSpecialChars(
                  acompanhante?.contato?.telefoneSecundario
                ) || null,
            }),
          },
        }),
      };

      const internacaoProdutosAdapted = internacaoProdutos?.length > 0 ?
        internacaoProdutos.map(item => ({
          ...(item.id && { id: item.id }),
          produto: {
            id: item.produto?.idAppHealth || item.produto?.id
          }
        })) : []

        const internacaoPrescricaoItensAdapted = internacaoPrescricaoItens?.length > 0 ?
        internacaoPrescricaoItens.map(item => ({
          produto: {
            id: item.drug.idAppHealth || item.drug.id
          },
          quantidade: item.amount,
          ...(item.useType && {tipoUso: item.useType}),
          ...(item.routeAdm && {viaAdministracao: item.routeAdm}),
          condutaEnfermagem: item.condutaEnfermagem,
        })) : []

      delete internacao.sujeitoAtencao.idade;
      return {
        ...internacao,
        agendamento: { id: agendamentoId },
        sujeitoAtencao: { id: sujeitoAtencao.id },
        dadosComplementares: dadosComplementaresAdapted,
        leitosOcupados: leitosOcupadosAdapted,
        procedimentos: procedimentosAdapted,
        cids: cidsAdapted,
        internacaoProdutos: internacaoProdutosAdapted,
        responsavel: responsavelAdaptado,
        ...(acompanhante && { acompanhante: acompanhanteAdaptado }),
        internacaoPrescricaoItens: internacaoPrescricaoItensAdapted,
      };
    };
    
    const handleSave = async () => {
      try {
        const {
          dadosComplementares
        } = internacao;

        if(!dadosComplementares?.convenio?.id) {
          const notification = {
            isOpen: true,
            message: "É necessário informar o convênio.",
            variant: "error",
          };
          atendimentoStore.showAlertMessage(notification);
          return;
        }

        setLoadingSave(true);

        const sujeitoAtencaoInternacao = getVariables();

        await saveInternacao({ sujeitoAtencaoInternacao });

        handleClose();
        const notification = {
          isOpen: true,
          message: "Internação salva com sucesso!",
          variant: "success",
        };
        atendimentoStore.showAlertMessage(notification);
      } catch (error) {
        console.log('error', error)
        const notification = {
          isOpen: true,
          message: "Erro ao salvar a internação.",
          variant: "error",
        };
        atendimentoStore.showAlertMessage(notification);
      } finally {
        setLoadingSave(false);
      }
    };

    const handleFaturamento = async () => {
      try {
        const urlAzulControle = await linkGuiaFaturamento({ internacaoId: internacao.id });
        window.open(urlAzulControle);
      } catch (error) {
        atendimentoStore.showAlertMessage({
          isOpen: true,
          message: "Erro ao acessar o financeiro",
          variant: "error",
        });
      }
    };

    const handleField = (field, value) => {
      setInternacao((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    };

    const renderTab = () => {
      const Component = componentTab[tabSelected];
      return <Component internacao={internacao} handleField={handleField} utilizaPlugzapi={utilizaPlugzapi}/>;
    };

    const handleClickDarAlta = () => {
      setOpenModalDarAlta(true)
    }

    const handleChangeDadosAlta = (value, field) => {
      setDadosAlta((prevState) => {
        return {
          ...prevState,
          [field]: value,
        }
      })
    }

    const handleDarAlta = async () => {
      try {
        const { termo } = dadosAlta.tuss39TerminologiaMotivoEncerramento || {};

        const variables = {
          sujeitoAtencaoInternamentoId: internacao.id,
          alta: {
            tuss39TerminologiaMotivoEncerramento: {id: dadosAlta.tuss39TerminologiaMotivoEncerramento?.id},
            dataAlta: moment(dadosAlta.dataAlta).format('YYYY-MM-DDTHH:mm:ss'),
            motivoAlta: termo,
          }
        }
        await darAltaPaciente(variables)
        handleClose()
      } catch (error) {
        console.error(error)
      }
    }

    const handleCloseModal = () => {
      const { dadosComplementares } = internacao
      const internacaoComparar = JSON.stringify(
        dadosInternacao
          ? dadosInternacao
          : {
            ...internacaoComparacao,
            dadosComplementares: {
              ...dadosComplementares,
              encaminhamento:
                internacaoComparacao.dadosComplementares.encaminhamento
                  ?.value,
            },
          }
      );
      const internacaoAtual = JSON.stringify({
        ...internacao,
        dadosComplementares: {
          ...dadosComplementares,
          encaminhamento: dadosComplementares.encaminhamento?.value,
        },
      });

      if (internacaoComparar !== internacaoAtual) {
        setOpenAvisoSalvar(true)
      } else {
        handleClose()
      }
    }

    return (
      <>
        <PageTitle title="Agenda - Agendamento Internação" />
        <DialogStyled
          maxWidth="md"
          fullWidth
          open={openModal}
          onClose={handleClose}
        >
          <Container>
            <Header>
              <Title>Internação</Title>
              <ContentButtons>
                <Button
                  shape="circle"
                  bgColor={"#00B0AE"}
                  onClick={handleClickDarAlta}
                  disabled={loadingSave || !internacao?.id}
                >
                  <AltaIcon />
                </Button>
                <Button
                  shape="circle"
                  bgColor={"#00B0AE"}
                  onClick={handleSave}
                  disabled={loadingSave || !!internacao.alta?.motivoAlta}
                >
                  <SaveIcon />
                </Button>
                <Button
                  shape="circle"
                  bgColor={"#00B0AE"}
                  onClick={handleFaturamento}
                  disabled={!internacao?.id || loadingSave}
                >
                  <FinanceiroMenuIcon />
                </Button>
                <Button
                  shape="circle"
                  bgColor={"#FB7676"}
                  onClick={handleCloseModal}
                  disabled={loadingSave}
                >
                  <CloseIcon />
                </Button>
              </ContentButtons>
            </Header>
            <MultiToggleButtons
              options={optionsTab}
              tabSelected={tabSelected}
              changeTabSelected={changeTabSelected}
              disabled={!hasAcessoCids}
              posicaoDesabilitada={[10]}
              buttonsContainerStyle={buttonsContainerStyle}
            />
            {renderTab()}
          </Container>
        </DialogStyled>
        {openModalDarAlta &&
          <ModalAltaPaciente
            open={openModalDarAlta}
            handleClose={() => setOpenModalDarAlta(false)}
            handleChange={handleChangeDadosAlta}
            dados={dadosAlta}
            internacao={internacao} 
            handleDarAlta={handleDarAlta}
            disabled={dadosAlta.dataAlta && dadosAlta.motivoAlta && internacao.id}
          />
        }
        {openAvisoSalvar &&
          <ModalAlerta
            open={openAvisoSalvar}
            onClose={() => setOpenAvisoSalvar(false)}
            messageContent={"Atenção! Os dados alterados não serão salvos. Tem certeza de que deseja sair?"}
            modalIcon={<AtencaoIcon size={'100px'} />}
            onPrimaryAction={handleClose}
            onSecondaryAction={() => handleSave()}
            primaryButtonLabel={"Sair"}
            secondaryButtonLabel={"Salvar"}
          />
        }
      </>
    );
  }
);

const stores = ["atendimentoStore", "prontuarioStore","unidadeStore"]
export default inject(...stores)(InternacaoModal);
