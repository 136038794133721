import React, { useEffect, useState } from "react";
import {
  Row,
  TextFieldSearchStyled,
  ContainerProcedimentos,
  ContentButtons,
  ContentTable,
} from "../../styles";
import Field from "../../components/Field";
import {
  findAllLeito,
} from "../../../../../services/InternacaoService";
import { Button } from "../../../../../components/ui/Buttons";
import {
  columnsLeitos,
  leitoOcupadoDefault,
} from "../../constants";
import Table from "../../../../../components/Table/Table";
import { ReactSelect } from "../../../../../components/Select/SelectSearch";
import CustomDateTimePicker from "../../../../../components/CustomDateTimePicker";
import { findAllTipoLeito } from "../../../../../services/LeitoService";
import { inject } from "mobx-react";

const LeitosOcupados = ({ internacao, handleField, atendimentoStore }) => {
  const { leitosOcupados } = internacao;

  const [leitoOcupadoNovo, setLeitoOcupadoNovo] = useState(
    leitoOcupadoDefault
  );
  const [isEdit, setIsEdit] = useState(false);
  const [tiposLeito, setTiposLeito] = useState([]);

  const {
    leito,
    tipoLeito,
    dataInicioOcupacao,
    dataFimOcupacao,
  } = leitoOcupadoNovo || {};

  const { data } = atendimentoStore.objView || {};
 
  const isDisabled = !tipoLeito

  useEffect(()=>{
    loadTiposLeito();
  },[])

  const loadTiposLeito = async () => {
      const response = await findAllTipoLeito({
        pageableDTO: {
          sortDir: "ASC",
          sortField: "codigo",
        },
      });
      setTiposLeito(response);
    };

  const handleLeitoOcupado = (value, field) => {
    setLeitoOcupadoNovo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleLeito = value => {
    setLeitoOcupadoNovo((prevState) => ({
      ...prevState,
      leito: value,
      tipoLeito: value?.tipoLeito
    }));
  };

  const handleAdicionaLeito = () => {
    const dadosLeitosOcupados = leitosOcupados;
    dadosLeitosOcupados.push(leitoOcupadoNovo);

    handleField("leitosOcupados", dadosLeitosOcupados);
  };

  const handleDelete = (data) => {
    const dadosLeitosOcupados = leitosOcupados;
    const index = dadosLeitosOcupados.findIndex(
      (leito) => leito.id === data.id
    );

    dadosLeitosOcupados.splice(index, 1);
    
    handleField("leitosOcupados", dadosLeitosOcupados);
  };

  const handleEdit = (data) => {
    setLeitoOcupadoNovo(data);
    setIsEdit(true);
  };

  const handleCancelarLeitoEdit = () => {
    setIsEdit(false);
  };

  const handleSalvarLeitoEdit = () => {
    const dadosLeitosOcupados = internacao.leitosOcupados;
    const index = dadosLeitosOcupados.findIndex(
      (procedimento) => procedimento.id === leitoOcupadoNovo.id
    );

    dadosLeitosOcupados[index] = leitoOcupadoNovo;

    setLeitoOcupadoNovo(leitoOcupadoDefault);
    handleField("leitosOcupados", dadosLeitosOcupados);
    setIsEdit(false);
  };

  const loadAllLeitos = async (search, loadedOptions, { page }) => {
    const variables = {
      search,
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortDir: "ASC",
        sortField: "nome",
      },
      livre: true,
      ativo: true,
    };

    const response = await findAllLeito(variables);
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <ContainerProcedimentos>
      <Row>
        <Field label="Leito" width="20%">
          <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllLeitos}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            value={leito}
            onChange={(e) => handleLeito(e)}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Field>
        <Field label="Tipo leito" width="20%">
          <ReactSelect
            value={tipoLeito}
            getOptionLabel={(option) => `${option.codigo} - ${option.descricao}`}
            getOptionValue={(option) => option.id}
            onChange={(e) => handleLeitoOcupado(e, "tipoLeito")}
            options={tiposLeito}
          />
        </Field>
        <Field label="Data início" width="20%">
          <CustomDateTimePicker
            onChange={(e) => handleLeitoOcupado(e, "dataInicioOcupacao")}
            value={dataInicioOcupacao}
            invalidLabel=""
            invalidDateMessage=""
            style={{ borderRadius: '8px'}}
            minDate={data}
          />
        </Field>
        <Field label="Data fim" width="20%">
          <CustomDateTimePicker
            onChange={(e) => handleLeitoOcupado(e, "dataFimOcupacao")}
            value={dataFimOcupacao}
            invalidLabel=""
            invalidDateMessage=""
            style={{ borderRadius: '8px'}}
            minDate={dataInicioOcupacao}
            disabled={!dataInicioOcupacao}
          />
        </Field>
        {isEdit ? (
          <ContentButtons>
            <Button
              bgColor="#FB7676"
              style={{ height: 40 }}
              padding={0}
              onClick={handleCancelarLeitoEdit}
            >
              Cancelar
            </Button>
            <Button
              bgColor={"#00B0AE"}
              style={{ height: 40 }}
              padding={0}
              onClick={handleSalvarLeitoEdit}
              disabled={isDisabled}
            >
              Salvar
            </Button>
          </ContentButtons>
        ) : (
          <Button
            bgColor={"#707C97"}
            style={{ height: 40 }}
            padding={0}
            onClick={handleAdicionaLeito}
            disabled={isDisabled}
          >
            Adicionar
          </Button>
        )}
      </Row>
      <ContentTable>
        <Table
          columns={columnsLeitos({ handleDelete, handleEdit })}
          dados={leitosOcupados}
        />
      </ContentTable>
    </ContainerProcedimentos>
  );
};

export default inject("atendimentoStore")(LeitosOcupados);
